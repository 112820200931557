import React from 'react';
import styled from '@emotion/styled';
import { __t } from 'common/i18n';
import Layout from 'common/Layout/Layout';
import { Button, Flex, Text } from 'common/styles';
import Head from 'next/head';
import { useRouter } from 'next/router';
// eslint-disable-next-line no-undef
type ErrorPageProps = { pagTitle: string, errorMsg: string, errorMsgSub: string, icon: JSX.Element; }

export default function ErrorPage({ pagTitle, errorMsg, errorMsgSub, icon }: ErrorPageProps) {
  const router = useRouter();
  return (
    <Layout disableFooter showStaticFooter disableMiniFooter style={{ display: 'flex', justifyContent: 'center' }}>
      <Head>
        <title>{__t(pagTitle)} | {__t('siteName')}</title>
      </Head>
      <Flex justify='center' align='center' direction={'column'}>
        <Icon500Wrapper justify='center' align='center'>
          {icon}
        </Icon500Wrapper>
        <Flex justify='center' align='center' direction={'column'} mt={6}>
          <Text variant="h1" color="default">{__t(errorMsg)}</Text>
          <Text variant="h3" color="default" mt={2}>{__t(errorMsgSub)}</Text>
          <BackBtn onClick={() => router.back()} variant='primaryB' mt={6}>
            {__t('common.back')}
          </BackBtn>
        </Flex>
      </Flex>
    </Layout>
  );
}

const BackBtn = styled(Button)(() => ({
  minWidth: '10.6rem',
  width: '100%',
}));

const Icon500Wrapper = styled(Flex)(({ theme }) => ({
  width: '40vw',
  height: 'auto',
  '& svg': {
    width: '100%',
    height: 'auto',
    maxWidth: '797px',
    maxHeight: '340px',
  },
  [theme.breakpoints.down('md')]: {
    width: '90%',
  },
}));
